@use './config/' as *;
@forward './main.scss';
@forward './events/fourstar-shoswcase';
@forward './events/eventlisting';
@forward './fixtures/schedule';

.waf-eventlisting{
    &.waf-component{
        @extend %mb-10;
    }
}
.waf-eventlisting{
    @extend %mt-8;
    .layout-wrapper{
        max-width: var(--container-max-width);
    }
    .waf-head{
        margin-top: 0;
        .title{
            @extend %d-block;
            @extend %title-40;
            @extend %mb-0;
        }
        .sub-title{
            @extend %mt-8;
            @extend %mb-0;
        }
    }
    .waf-body{
        max-width: 88rem;
        @extend %mx-auto;
        @extend %mt-10;
    }
    .meta{
        @extend %body-m;
        &-origin{
            &::before{
                font-size: 2.4rem;
            }
        }
    }
    .card{
        &-content{
            @extend %mt-4;
        }
        &-label{
            @extend %mt-4;
        }
    }
    .content{
        &-item{
            + .content-item{
                @extend %mt-4;
            }
        }
        &-label{
            @extend %neutral-70;
        }
        &-title{
            @extend %mt-2;
            @extend %body-lb;
        }
    }
}

@include mq(col-tablet){
    .waf-eventlisting{
        &.waf-component{
            margin-bottom: var(--space-20);
        }
    }
    .waf-eventlisting{
        margin-top: var(--space-20);
        .waf-head{
            .title{
               font-size: 4rem;
            }
            .sub-title{
                margin-top: var(--space-20);
            }
        }
        .card{
            &-right{
                position: relative;
            }
            &-label{
                margin: 0;
                @include position(null, 0, 28.7%);
            }
        }
    }
}

@include mq(col-lg){
    .waf-eventlisting{
        .meta{
            &-origin{
                white-space: unset;
            }
        }
    }
}